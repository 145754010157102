import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default{
	computed:{
		...mapGetters(['getDialogInfo', 'getSpinnerStatus']),
		isDialogOn()
		{
			return this.getDialogInfo.dialogOn;
		}
	},
	methods:{
		...mapActions(['modifyDialog', 'modifySpinnerStatus']),
		//Four parameters needed to open dialog box
		//type : primary, secondary, '', warning, error
		openDialogBox(message, title, buttonTitle, type)
		{
			let dialogInfo = {
				message,
				title,
				buttonTitle,
				type,
				dialogOn : true,
			};

			this.modifyDialog(dialogInfo);
		},
		//Close it
		closeDialogBox()
		{
			let dialogInfo = {
				message: '',
				title: '',
				buttonTitle: '',
				type: '',
				dialogOn : false,
			};

			this.modifyDialog(dialogInfo);
		},
		showGeneralSpinner()
		{
			this.modifySpinnerStatus(true);
		},
		hideGeneralSpinner()
		{
			this.modifySpinnerStatus(false);
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace(',', '.')
			return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		format2Digits(value) {
			let val = (value/1).toFixed(2).replace(',', '.')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		formatDate(d) {
			return d.substring(5,7) + '-' + d.substring(8,10) + '-' + d.substring(0,4);
		}
	},
};