<template>
  <v-app>

    <!-- Header -->
    <v-app-bar
      app
      color="white"
    >
      <div class="d-flex headerLogo">
        <div class="mt-1">
          <a :href="infoObject.urlLink">
            <picture>
              <source media="(max-width: 959px)" :srcset="infoObject.smallImg" height="60" alt="Waste Connections" /> <!-- Mobile image -->
              <source media="(min-width: 960px)" :srcset="infoObject.mediumImg" alt="Waste Connections" /> <!-- Desktop image -->
              <img :src="infoObject.bigImg" height="80" alt="Waste Connections" /> <!-- Default -->
            </picture>
          </a>
        </div>
      </div>

      <div v-if="getOrgLogo != null && getUserInfo.isLogged" class="secondLogo">
          <picture class="pictureContainer">
            <source media="(max-width: 959px)" :srcset="getOrgLogo" /> <!-- Mobile image -->
            <source media="(min-width: 960px)" :srcset="getOrgLogo" /> <!-- Desktop image -->
            <img :src="getOrgLogo" class="logoImage"/> <!-- Default -->
          </picture>
      </div>

      <v-app-bar-nav-icon x-large class="hidden-lg-and-up justify-start r-0 sidenav-btn" @click.stop="drawer = !drawer"/>

      <div class="header-text text-right d-none d-lg-flex d-xl-flex lastContainer">
        <router-link class="grey--text text--darken-4 pa-6 mb-n2" to="/Organization">Organization</router-link>
        <router-link v-if="getCountryVersion != 'CAN'" class="grey--text text--darken-4 pa-6 mb-n2" to="/ExportRequests">Export Requests</router-link>
        <router-link v-if="isUserSuperUser" class="text--darken-4 pa-6 mb-n2" :to="'/CreateUser'">Create User</router-link>
        <router-link class="text--darken-4 pa-6 mb-n2" v-if="getUserInfo.isLogged" @click.native="logUserOut()" :to="'/'">Log Out</router-link>
        <router-link class="text--darken-4 pa-6 mb-n2" v-if="!getUserInfo.isLogged" :to="'/'">Log In</router-link>
      </div>

      <v-navigation-drawer v-model="drawer" absolute class="header-text text-left hidden-lg-and-up" height="100vh">
        <v-list class="pt-10">
            <v-list-item class="ml-n2">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-folder-table</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="subtitle-2">
                <router-link to="/Organization">Organization</router-link>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isUserSuperUser" class="ml-n2">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="subtitle-2">
                <router-link to="/CreateUser">Create User</router-link>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="ml-n2">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-inbox-arrow-down</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="subtitle-2">
                <router-link to="/ExportRequests">Export Requests</router-link>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="ml-n2">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="getUserInfo.isLogged" class="subtitle-2">
                <router-link to="/" @click="logUserOut()">Log Out</router-link>
              </v-list-item-content>
              <v-list-item-content v-if="!getUserInfo.isLogged" class="subtitle-2">
                <router-link to="/">Log Out</router-link>
              </v-list-item-content>
            </v-list-item>
        </v-list>
      </v-navigation-drawer>

    </v-app-bar>

    <!-- Current Page -->
    <v-main class="mx-lg-16 px-lg-16 mx-3">
      <router-view/>
    </v-main>

    <!-- Footer -->
    <v-footer class="text-left">
      <div class="pa-lg-2">
        <span>
          Copyright &copy; {{new Date().getFullYear()}} {{infoObject.footerTitle}}
          <!--<span class="desktop">&nbsp;&nbsp;|&nbsp;&nbsp;</span>-->
        </span>
        <!--
        <span>
          <a href="https://www.wasteconnections.com/terms-of-use/">Terms Of Use</a>
          <span class="desktop">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        </span>
        <span>
          <a href="https://www.wasteconnections.com/pricing-policy/">Pricing Policy</a>
          <span class="desktop">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        </span>
        <span>
          <a href="https://www.wasteconnections.com/privacy-policy/">Privacy Policy</a>
          <span class="desktop">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        </span>
        <span>
          <a href="https://www.wasteconnections.com/environmental-surcharges/">Environmental Surcharges.</a>
        </span>-->
      </div>
    </v-footer>

    <DialogBox />
    <LoadingSpinner />

  </v-app>
</template>

<script>
		//Check if we have any parameters present in the URL
		if(window.location.search != '')
		{
			var newURL = location.protocol + '//' + location.host + '/#'+ location.pathname + window.location.search;
			window.location.href=newURL;
		}

  import { initializeApp, getApps, getApp } from 'firebase/app'
  import { getAuth } from 'firebase/auth';
  import firebaseConfig from '@/config/firebaseConfig.js';
  import {mapGetters} from 'vuex';
  import DialogBox from '@/components/DialogBox.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  const auth = getAuth(app);

  export default {
    name: 'App',

    data: () => ({
      drawer: null,
      quickLinks: false,
      dashboard: false,
      orgRes: null,
      isCargill: true,
      infoObject: {
          smallImg: '',
          mediumImg: '',
          bigImg: '',
          footerTitle: '',
          siteTitle: '',
          urlLink: '',
        },
    }),
    computed: {
     ...mapGetters(['getUserInfo', 'isUserSuperUser', 'getOrgLogo', 'getCountryVersion']),
    },
    mounted() {
     //Load language and logos
      this.loadCountryInfo();
    //  window.document.title(this.infoObject.siteTitle);
    },
    components: {
      DialogBox,
      LoadingSpinner
    },
    methods: {
      logUserOut() {
        auth.signOut().then( () => {

        }).catch(function(error)
        {
          console.log('Error signing out!');
          console.log(error);
        });
      },
      loadCountryInfo()
      {
        //Default is US
        if(this.getCountryVersion == 'CAN')
        {
            this.infoObject = {
              smallImg: 'https://media.licdn.com/dms/image/C560BAQFAP4i7TXyizQ/company-logo_200_200/0/1630581454215/waste_connections_of_canada_logo?e=2147483647&v=beta&t=U35ju6JD6riz5fh-YAgyMBcp_-64WpdFnmiDuJwaVKI',
              mediumImg: 'https://dpbvj4a9anukr.cloudfront.net/c7941b0f-f427-4ef4-b63d-c0edd6af5e90/72c0e820-2804-483a-bdca-f8546d9e60a9/5efea414ff24e18bb3dfc7244a69ed8b/wc-logo-img.png',
              bigImg: 'https://dpbvj4a9anukr.cloudfront.net/c7941b0f-f427-4ef4-b63d-c0edd6af5e90/72c0e820-2804-483a-bdca-f8546d9e60a9/5efea414ff24e18bb3dfc7244a69ed8b/wc-logo-img.png',
              footerTitle: 'Waste Connections Canada',
              siteTitle: 'Waste Connections Canada - National Account Portal',
              urlLink: 'https://www.wasteconnectionscanada.com/',
            };
        }
        else
        {
          this.infoObject = {
            smallImg: 'https://www.smartsoftdq.com/wp-content/uploads/2017/04/WC-300x300.png',
            mediumImg: 'https://d16bl9hbknyxy0.cloudfront.net/c7941b0f-f427-4ef4-b63d-c0edd6af5e90/9330d04b-5764-4aaf-9e5e-d1e6cd9543bd/wci-brand-signature-3-color-horizontal.png?w=2400&h=2400&fit=inside&Expires=9223372036854775&Signature=iDpQU1FUHbwMYdp7v33j-4EDC0ixkiJT~Whbo~CmpJp76aeym-GTPkUL3lnM1TQQkSnH5WuobABi4oG-~e-2JT1m0zWfOwR~r3Dvv94gmI1fsxO6sLT2PaVW3wwUWnyqi9J~yCvvIBdu1pjz1fy6BIvQ3eau2azN-DK2UqNpJbMZ3ZUAyRWefNaJHFTYcbnN3Uj2K6gzAa3z1JWZKPtqfVwUki2bOO7mFAz2xIEnmniYtU263kP4wX4qLqX8yV~Rl19JlceaKbl6MOEst4WhgU4xLOZq-jpzOWPTg6ZtRawSfXo8ZG1ag-Jp~ndN4d1Gtka7SnxNJxDeX6a2sGx-8Q__&Key-Pair-Id=K2SKI2Y48Y62V1',
            bigImg: 'https://d16bl9hbknyxy0.cloudfront.net/c7941b0f-f427-4ef4-b63d-c0edd6af5e90/9330d04b-5764-4aaf-9e5e-d1e6cd9543bd/wci-brand-signature-3-color-horizontal.png?w=2400&h=2400&fit=inside&Expires=9223372036854775&Signature=iDpQU1FUHbwMYdp7v33j-4EDC0ixkiJT~Whbo~CmpJp76aeym-GTPkUL3lnM1TQQkSnH5WuobABi4oG-~e-2JT1m0zWfOwR~r3Dvv94gmI1fsxO6sLT2PaVW3wwUWnyqi9J~yCvvIBdu1pjz1fy6BIvQ3eau2azN-DK2UqNpJbMZ3ZUAyRWefNaJHFTYcbnN3Uj2K6gzAa3z1JWZKPtqfVwUki2bOO7mFAz2xIEnmniYtU263kP4wX4qLqX8yV~Rl19JlceaKbl6MOEst4WhgU4xLOZq-jpzOWPTg6ZtRawSfXo8ZG1ag-Jp~ndN4d1Gtka7SnxNJxDeX6a2sGx-8Q__&Key-Pair-Id=K2SKI2Y48Y62V1',
            footerTitle: 'Waste Connections',
            siteTitle: 'Waste Connections - Large Account Portal',
            urlLink: 'https://www.wasteconnections.com/',
          };

        }
      }
    }
  }

</script>

<style lang="scss">
  // * Default
  #app{font-family: Roboto, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;text-align: center;}
  .pac-item{font-family:Roboto,sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
  .pac-item-query{font-family:Roboto,sans-serif !important;font-size:16px}
  .pac-item:hover{background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
  .pac-container{color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
  .pac-icon,.pac-icon-marker{width:0 !important;height:0 !important;background:none !important;background-image:none !important}
  .loginDiv { min-width:200px; }
  .loginDiv > a { margin-left:1em;}

  // * Custom
  .d-grid { display:grid; }
  .fade-enter-active, .fade-leave-active { transition: opacity .2s; }
  .fade-enter, .fade-leave-active { opacity: 0; }
  .card-header, .card-header .v-toolbar__content { height: 60px !important; }
  .container { max-width: none !important; }
  .remove-toolbar-margin .v-toolbar__content { margin: 0 !important; }
  .square-btn { min-width: 10px !important; width: 36px; height: 36px; }
  .l-0 { left: 0 !important; } .r-0 { right: 0; }
  .is-absolute { position: absolute !important; }
  .full-width { width: 100%; } .half-width { width: 50% } .third-width { width: 33% }
  .hide { display: none !important; }
  .v-toolbar__content .v-icon { padding: 0 8px 0 16px; }

  // * Header
  .header-text a { text-decoration: none; }
  .v-application .header-text a { color: rgba(0, 0, 0, 0.87) !important; }
  .dropdownMenu { position:absolute; top: 80px; border-top: 4px solid rgb(3, 99, 73) !important; text-align:left; background-color: #F5F5F5; }
  .dropdown-heading { font-weight: bold; color: #9E9E9E; text-transform:uppercase; font-size: 17px }
  div.header-text > a:hover { border-bottom: 4px solid rgb(3, 99, 73); padding-bottom:0 !important; }
  .v-toolbar__content  { margin: 0 7%; height: 80px !important; }
  .m-bottom-n-12 { margin-bottom: -12px; }
  .v-overlay { height: 100vh; }
  .v-list-group__header__append-icon { display: none !important; }
  .full-center {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  // * XS and SM
  @media only screen and (max-width: 959px) {
    header { height: 60px !important; }
    div.v-toolbar__content { height: 60px !important; margin: 0 !important; padding: 0 0 0 8px !important; }
    .desktop { display: none !important; }
    .v-main { padding-top: 60px !important }
    .half-width-sm { width: 50%; }
    .v-slide-group__prev {display: none !important; }
  }

  // * LG and XL
  @media only screen and (min-width: 960px) {
    header { height: 80px !important; }
    .v-main { padding-top: 100px !important }
    .mobile { display: none !important; }
  }

  // * Footer
  .v-footer a { color: rgba(255, 255, 255, 0.57) !important; text-decoration: none; }
  @media only screen and (min-width: 960px) {
    .v-footer { height: 80px; background-color:rgb(1, 62, 116) !important; color: rgba(255, 255, 255, 0.65) !important; }
    .v-footer div { margin: 0 6%; top:-15px; position: relative; font-weight: 300; font-size: 14px; }
  }
  @media only screen and (max-width: 959px) {
    .v-footer { min-height: 75px; background-color:rgb(1, 62, 116) !important; color: rgba(255, 255, 255, 0.65) !important; }
    .v-footer div { margin: 0; position: relative; font-weight: 300; font-size: 14px; }
    .v-footer div > span { display:flex; margin-left: 0; padding-top: 5px; }
  }

  /* Modifications for the alignment of the logo */
  .headerLogo
  {
    flex:1 1 50%;
    justify-content: space-between;
  }

  .secondLogo
  {
    max-height:75px;
    flex:1 1 auto;
    max-width:200px;
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .secondLogo img.logoImage
  {
    max-width:200px;
    position:relative;
    max-height:75px;
  }

  .lastContainer
  {
    flex:1 1 50%;
    justify-content: flex-end;
  }

  .logoImage
  {
    display:flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 1263px)
  {
    .secondLogo
    {
        position:absolute;
        left:0;
        right:0;
        width:100%;
        max-width:100%;
    }
  }

  @media only screen and (max-width: 959px)
  {
    .secondLogo
    {
      max-height:50px;
    }

    .secondLogo img.logoImage
    {
      max-height:50px;
    }
  }
</style>