	<template>
	<div class="loadingSpinner" v-show="(isProp && isOn) ||getSpinnerStatus">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
</template>

<script>
import generalities from '@/mixins/generalities';
export default {
  props:['isProp', 'isOn'],
  mixins:[generalities],
};
</script>

<style scoped="true">
div.loadingSpinner
{
  background-color: rgba(236,236,236,0.8);
  position:fixed;
  z-index:2;
  top:0;
  width:100vw;
  height:100vh;
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
  align-items:center;
  -webkit-justify-content:center;
  justify-content: center;
}
</style>
