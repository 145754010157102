import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store';
import VueMeta from 'vue-meta';
import Vuex from 'vuex';
import { auth, database, functions, appCheck } from './plugins/firebaseClient';

Vue.config.productionTip = false

Vue.use(VueMeta);
Vue.use(Vuex);

Vue.prototype.$auth = auth;
Vue.prototype.$database = database;
Vue.prototype.$functions = functions;
Vue.prototype.$appCheck = appCheck;

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
