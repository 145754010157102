import Vuex from 'vuex';
import Vue from 'vue';
import { get, child, ref } from 'firebase/database';


Vue.use(Vuex);

//Logic for determining the website URL
//CAN?
let theURL = '';
if(process.env.VUE_APP_COUNTRY === 'CAN')
{
	theURL = 'https://wcc-national-account-prod.web.app';
}
else
{
	theURL = 'https://large-account-portal-prod.web.app';
}


let theStore = new Vuex.Store({
	state:{
		//isLogged - whether user is logged or not; email, uid; isLoaded - if we've gotten this status from a login/logout.
		//So if false, user never attemtped any login/logout for this session
		userInfo: {
			isLogged: false,
			email: '',
			isLoaded: false,
			uid: '',
		},
		isSuperUser: false,
		dialogInfo:{
			message: '',
			title: '',
			buttonTitle: '',
			type: '',
			dialogOn : false,
		},
		exportRequest: null,
		orgInfo: null,
		orgLogo: null,
		spinnerOn: false,
		websiteURL: theURL,
		countryVersion: process.env.VUE_APP_COUNTRY
	},
	getters:{
		getUserInfo(state)
		{
			return state.userInfo;
		},
		isUserLoggedIn : state =>
		{
			return state.userInfo.isLogged;
		},
		isLoginStatusLoaded: state =>
		{
			return state.userInfo.isLoaded;
		},
		isUserSuperUser(state)
		{
			return state.isSuperUser;
		},
		getDialogInfo(state)
		{
			return state.dialogInfo;
		},
		getOrgInfo(state)
		{
			return state.orgInfo;
		},
		getSpinnerStatus(state)
		{
			return state.spinnerOn;
		},
		getOrgLogo(state)
		{
			return state.orgLogo;
		},
		getWebsiteURL(state)
		{
			return state.websiteURL;
		},
		getExportRequest(state)
		{
			return state.exportRequest;
		},
		getCountryVersion(state)
		{
			return state.countryVersion;
		}
	},
	mutations: {
		MODIFY_USER_INFO(state, userInfo)
		{
			state.userInfo = userInfo;
		},
		USER_LOGGED_OUT(state)
		{
			state.userInfo = {
				isLogged: false,
				email: '',
				isLoaded: true,
				uid: '',
			};
			state.accountInfo = {
				isLoading: false,
				isLoaded: false,
				accounts: [],
			};
		},
		MODIFY_SUPER_USER_INFO(state, newValue)
		{
			state.isSuperUser = newValue;
		},
		MODIFY_DIALOG_INFO(state, payload)
		{
			state.dialogInfo = payload;
		},
		MODIFY_SPINNER_STATUS(state, payload)
		{
			state.spinnerOn = payload;
		},
		MODIFY_ORG_INFO(state, payload)
		{
			state.orgInfo = payload;
		},
		MODIFY_ORG_LOGO(state, payload)
		{
			state.orgLogo = payload;
		},
		MODIFY_EXPORT_REQUEST(state, payload)
		{
			state.exportRequest = payload;
		}
	},
	actions:{
		modifyUserInfo({commit}, userInfo)
		{
			commit('MODIFY_USER_INFO', userInfo);
		},
		modifyOrgInfo({commit}, payload)
		{
			commit('MODIFY_ORG_INFO', payload);
		},
		modifyOrgLogo({commit}, payload)
		{
			commit('MODIFY_ORG_LOGO', payload);
		},
		checkIfSuperUser: async({commit, getters}, { database }) => {

			try {
				const snapshot = await get(child(ref(database), `superusers/${getters.getUserInfo.uid}`));
				if(snapshot.exists()) {
					commit('MODIFY_SUPER_USER_INFO', true);
				} else {
					commit('MODIFY_SUPER_USER_INFO', false);
				}
			} catch (error) {
				console.log('Error authenticating!', error);

				commit('MODIFY_DIALOG_INFO', {
					message: error.message,
					title: 'Error getting superuserinfo',
					buttonTitle: 'OK',
					type: 'error',
					dialogOn: true,
				});

				commit('MODIFY_SUPER_USER_INFO', null);
			}

		},
		userLoggedOut({commit})
		{
			commit('USER_LOGGED_OUT');
			commit('MODIFY_SUPER_USER_INFO', false);
			commit('MODIFY_ORG_INFO', null);
			commit('MODIFY_ORG_LOGO', null);
		},
		modifyDialog({commit}, payload)
		{
			commit('MODIFY_DIALOG_INFO', payload);
		},
		modifySpinnerStatus({commit}, payload)
		{
			commit('MODIFY_SPINNER_STATUS', payload);
		},
		modifyExportRequest({commit}, payload)
		{
			commit('MODIFY_EXPORT_REQUEST', payload);
		}
	}
});


export default theStore;