<template>
	<div v-if="isDialogOn">
		<v-dialog
		v-model="mutableDialogOn"
		persistent
		width="500"
		>
		<v-card>
			<v-toolbar dark :color="getDialogInfo.type">
			<v-toolbar-title>{{getDialogInfo.title}}</v-toolbar-title>
			</v-toolbar>

			<v-card-text class="textContainer">
			{{getDialogInfo.message}}
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				:color="getDialogInfo.type"
				text
				@click="closeDialogBox()"
			>
				{{getDialogInfo.buttonTitle}}
			</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
import generalities from '@/mixins/generalities';

export default {
	data: function(){return {
		mutableDialogOn: true,
	}},
	mixins: [generalities],	
};
</script>

<style scoped="true">
	.textContainer
	{
		padding:25px !important;
		font-size:1em;
	}
</style>