import Vue from 'vue'
import VueRouter from 'vue-router'
import { initializeApp, getApps, getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';
import firebaseConfig from '@/config/firebaseConfig.js';
import store from './../store';

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);


Vue.use(VueRouter)
//US routes (default app)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/LoginPage.vue')
  },
  {
    path: '/OrdinaryNotProtected',
    name: 'OrdinaryNotProtected',
    component: () => import('../components/OrdinaryNotProtected.vue')
  },
  {
    path: '/EmailRedirected',
    name: 'EmailRedirected',
    component: () => import('../components/FirebaseActionHandler.vue')
  },
  {
    path: '/OrdinaryProtected',
    name: 'OrdinaryProtected',
    component: () => import('../components/OrdinaryProtected.vue'),
    meta:{
      mustBeLoggedIn: true,
    },
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      mustBeLoggedIn: true
    }
  },
  {
    path: '/site/:id',
    name: 'Site',
    props: {isSite: true},
    component: () => import('../views/Site.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/businessunit/:id',
    name: 'BusinessUnit',
    props: {isSite: false},
    component: () => import('../views/BusinessUnit.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/corporate/:id',
    name: 'Corporate',
    props: {isSite: false},
    component: () => import('../views/Corporate.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () => import('../views/Organization.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/ExportRequests',
    name: 'ExportRequests',
    component: () => import('../views/ExportRequests.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/CreateUser',
    name: 'CreateUser',
    component: () => import('../components/CreateUser.vue'),
    meta: {
      mustBeLoggedIn: true,
      mustBeSuperUser: true
    }
  }
];

//CAN routes (addition)
const routesCAN = [
  {
    path: '/',
    meta:{
      title: 'WCC National Accounts',
    },
    name: 'Home',
    component: () => import('../components/LoginPage.vue')
  },
  {
    path: '/EmailRedirected',
    name: 'EmailRedirected',
    component: () => import('../components/FirebaseActionHandler.vue')
  },
  //For testing purposes
  {
    path: '/account/:id',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      mustBeLoggedIn: true
    }
  },
  {
    path: '/Province/:id',
    name: 'Province',
    props: {theType: 'Province'},
    component: () => import('../views/Canada/Province.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/NationalAccount/:id',
    name: 'NationalAccount',
    props: {theType: 'NationalAccount'},
    component: () => import('../views/Canada/NationalAccount.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () => import('../views/Organization.vue'),
    meta: {
      mustBeLoggedIn: true
    },
  },
  {
    path: '/CreateUser',
    name: 'CreateUser',
    component: () => import('../components/CreateUser.vue'),
    meta: {
      mustBeLoggedIn: true,
      mustBeSuperUser: true
    }
  }
];

let routesToUse = store.getters.getCountryVersion == 'CAN' ? routesCAN : routes;

const router = new VueRouter({
  routes: routesToUse
});

//Navigation guards for auth pages. Use .some if nested? check it out
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {

  if(to.meta.title)
  {
    document.title = to.meta.title;
  }


  //Keep it v3 in case we migrate. Check with firebase in case user logged out
  if(to.meta.mustBeLoggedIn && !auth.currentUser)
  {
    //Need to redirect to login! Keep the old URL as nextURL so we can redirect back to it after login
    let goingTo = to.path;

    //Exceptions here:
    if(to.name == 'CreateUser')
    {
      goingTo = '/';
    }

    next({name: 'Home', query: {
      nextUrl: goingTo,
    }});

  }
  else
  {
      //Another possibility: must be super user (no need to check if loggede since impossible that way)
      if(to.meta.mustBeSuperUser && !store.getters.isUserSuperUser)
      {
        next({name: 'Home'});
      }
      //Else - proceed
      else
      {

        next();
      }
  }

});

export default router;
