import { initializeApp, getApps, getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getFunctions, httpsCallable,  connectFunctionsEmulator } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import configPublicFirebase from '@/config/firebaseConfig';
import store from '../store';
import router from '../router';

// Initialize Firebase App
const firebaseConfig = configPublicFirebase;
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()

// Initialize Firebase Services
const auth = getAuth(app)
const database = getDatabase(app)
const functions = getFunctions(app)
if (process.env.NODE_ENV === 'development' ) {
    // https://firebase.google.com/docs/app-check/web/debug-provider
    // to generate the token (copy from debug console and paste in Firebase -> Manage debug tokens)
    // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_CHECK_DEBUG_TOKEN;
}
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true
})

onAuthStateChanged(auth, (user) => {
    console.log('onAuthStateChanged', user);
    if (user) {
      store.dispatch('modifyUserInfo', { email: user.email, isLogged: true, isLoaded: true, uid: user.uid });

      // Check if superuser
      store.dispatch('checkIfSuperUser', { database: database });

      // Spinner on
      store.dispatch('modifySpinnerStatus', true);

      const isNationalAccountWCC = store.getters.getCountryVersion === 'CAN';

      const callFirebase = httpsCallable(functions, 'customRestAPI');
      callFirebase({ typeOfAction: 'getOrganigramStructure', isNationalAccountWCC })
        .then((result) => {
          store.dispatch('modifyOrgInfo', JSON.parse(result.data));
          store.dispatch('modifyOrgLogo', JSON.parse(result.data)[0].logo);
        })
        .catch((err) => {
          console.log('An error occurred at getOrganigramStructure');
          console.log(err);
        })
        .finally(() => {
          // Spinner off
          store.dispatch('modifySpinnerStatus', false);
        });

      // On login - check if nextURL has something in it
      if (router.history.current.query.nextUrl) {
        router.push(router.history.current.query.nextUrl);
      } else {
        // Always send here
        router.push({ name: 'Organization' });
      }
    } else {
      store.dispatch('userLoggedOut');
    }
  })

// Connect to Emulators (if enabled)
if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_EMULATOR === 'TRUE') {
    try {
        connectAuthEmulator(auth, `http://${process.env.VUE_APP_EMULATOR_AUTH_HOST}:${process.env.VUE_APP_EMULATOR_AUTH_PORT}`)
    } catch (error) {
        if (error instanceof Error && !error.message.includes('auth/emulator-config-failed')) {
            console.error('Error connecting to Firebase Authentication Emulator:', error.message)
        }
    }

    try {
        connectDatabaseEmulator(database, process.env.VUE_APP_EMULATOR_DATABASE_HOST, Number(process.env.VUE_APP_EMULATOR_DATABASE_PORT))
    } catch (error) {
        if (error instanceof Error && !error.message.includes('Firestore has already been started')) {
            console.error('Error connecting to Firebase Firestore Emulator:', error.message)
        }
    }

    try {
        connectFunctionsEmulator(functions, process.env.VUE_APP_EMULATOR_FUNCTIONS_HOST, Number(process.env.VUE_APP_EMULATOR_FUNCTIONS_PORT))
    } catch (error) {
        if (error instanceof Error && !error.message.includes('Functions emulator config failed')) {
            console.error('Error connecting to Firebase Functions Emulator:', error.message)
        }
    }
}

export { auth, database, functions, appCheck };